import Login from "./Pages/Login";
import Cookies from "universal-cookie";
import Navbar from "./Components/Navbar";
import Main from "./Main";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Trupe from "./Pages/Trupe";
import { useEffect, useState } from "react";
import axios from "axios";
axios.defaults.baseURL = "https://gestapo.sisc.ro/api/";

function App() {
  const cookies = new Cookies();

  let user = cookies.get("user");
  // console.log(userFromCookie);

  if (user == undefined)
    return (
      <div>
        <Login />
      </div>
    );
  else {
    return (
      <>
        <Navbar />
        <Main />
      </>
    );
  }
}

export default App;
