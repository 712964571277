import React, { useState } from "react";
import { login } from "../Api/api";
import Cookies from "universal-cookie";
import google from "../assets/images/google.png";

function Login() {
  const googleAuth = async (e) => {
    e.preventDefault();
    await window.open(`https://gestapo.sisc.ro/api/auth/`, "_self");
  };

  return (
    <div className="login">
      <h1 className="title">STS GESTAPO</h1>
      <button onClick={(e) => googleAuth(e)} className="googleBtn">
        <img src={google} alt="google icon" className="googleIcon" />
        <span className="googleText">Sign in with Google</span>
      </button>
    </div>
  );
}

export default Login;
