import React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import logo from "./../assets/images/logo.png";
function Navbar() {
  // const cookies = new Cookies();
  // let user = cookies.get("user");

  const logout = async () => {
		await window.open("http://localhost:1235/api/auth/logout", "_self");
	};

  return (
    <div className="navbar">
      <img src={logo} alt="logo" className="logo" />
      <div className="options">
        <Link to="/">Acasa</Link>
        <Link to="/trupe">Trupe</Link>
        <a
          onClick={logout}
        >
          <span className="material-symbols-outlined btnLogout">logout</span>
        </a>
      </div>
    </div>
  );
}

export default Navbar;
